export const codeMask = `
<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-card-code title="  أضافة نقطة ضعف">
          <title></title>
          <b-row :disable="true">
            <!-- <b-col md="6" xl="4">
              <validation-provider
                #default="{ errors }"
                name="documentType"
                rules="required"
              >
                <b-form-group
                  label-for="cardNumber"
                  :state="errors.length > 0 ? false : null"
                >
                  <label> نوع الوثيقة</label>
                  <v-select
                    v-model="selected2"
                    label="title"
                    :options="optionDocuments"
                  />
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col> -->
            <!-- <b-col md="6" xl="4">
              <b-form-group label-for="number">
                <validation-provider
                  #default="{ errors }"
                  name="Ndocmnt"
                  rules="required"
                >
                  <label>  نقطة الضعف</label>

                  <b-form-input
                    v-model="number"
                    :state="errors.length > 0 ? false : null"
                    
                  />  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col>
              <b-col md="6" xl="4">
              <b-form-group label-for="number">
                <validation-provider
                  #default="{ errors }"
                  name="Ndocmnt"
                  rules="required"
                >
                  <label>  نقطة الضعف الفرعية</label>

                  <b-form-input
                    v-model="number"
                    :state="errors.length > 0 ? false : null"
                    
                  />  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </b-col> -->
             <b-col md="6" xl="2">
              <!-- <validation-provider #default="{ errors }" name="Weak" rules="required"> -->
              <b-form-group label-for="time">
                <label> نقاط الضعف</label>
                <v-select
                  :disabled="!isDisabled"
                  v-model="selectedWeak"
                  label="title"
                  :options="optionWeakness"
                />
              </b-form-group>
              <!-- <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
                 </validation-provider> -->
            </b-col>

            <!-- delimiters -->
            <b-col md="1" xl="4">
              <b-form-group label-for="delimiter">
                <label> الملاحظات</label>
                <b-form-textarea id="textarea-default" placeholder="الملاحظات" />
              </b-form-group>
            </b-col>

            <!-- Custom Delimiters -->
            <!-- <b-col
        md="6"
        xl="4"
      >
        <b-form-group
          label="Custom Delimiters"
          label-for="customDelimiter"
        >
          <cleave
            id="customDelimiter"
            v-model="form.customDelimiter"
            class="form-control"
            :raw="false"
            :options="options.customDelimiter"
            placeholder="Delimiter: ['.', '.', '-']"
          />
        </b-form-group>
      </b-col> -->

            <!-- prefix -->

            <b-col class="but" md="" xl="2">
              <b-form-group>
                <b-form-checkbox
                  v-model="terms"
                  plain
                 
                  class="custom-control-warning"
                >
                  <label> لا يوجد نقاط ضعف</label>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col class="but" md="6" xl="2">
              <b-form-group label=" " label-for="prefix">
                <b-button :disabled="!isDisabled" variant="purple">
                  <span class="align-middle"> أضافة نقطة </span>
                </b-button>
              </b-form-group>
            </b-col>
            <b-col class="but" md="6" xl="2">
              <b-form-group label=" " label-for="prefix">
                <b-button :disabled="!isDisabled" variant="purple"
                  ><span class="align-middle"> حذف </span>
                </b-button>
              </b-form-group>
            </b-col>
            <b-col class="" md="6" xl="2">
              <b-button variant="bl" @click="next">
                <span class="align-middle"> الصفحة التالية</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-code>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
// import FormTextAreaDefault from '..\form-textarea\FormTextArea.vue'
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { BRow, BCol, BFormGroup, BInputGroupPrepend, BInputGroup } from "bootstrap-vue";
import Cleave from "vue-cleave-component";
// eslint-disable-next-line import/no-extraneous-dependencies
import "cleave.js/dist/addons/cleave-phone.us";
// import { codeMask } from "./code";
import {
  BFormDatepicker,
  BFormCheckbox,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
  BForm,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vueCountryRegionSelect from "vue-country-region-select";
import vueCountriesCities from "vue-countries-cities";
// import {
//   BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
// } from 'bootstrap-vue'
import Vue from "vue";
Vue.use(vueCountryRegionSelect);
Vue.component("countriesCities", vueCountriesCities);
export default {
  name: "",
  components: {
    BFormInvalidFeedback,

    BForm,
    ValidationObserver,
    ValidationProvider,
    vueCountriesCities,
    vueCountryRegionSelect,
    BCardCode,
    BButton,
    BFormInput,
    BFormFile,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker,
    Cleave,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BRow,

    BCol,
  },
  data() {
    return {
  
      terms:true
     ,
     

      codeMask,
    };
  },
  
  computed: {
    isDisabled: function () {
      return !this.terms;
    },
    // isDisabled2: function () {
    //   return !this.terms2;
    // },
  },
 
  methods: {
    adddocument() {
      return new Promise((resolve, reject) => {
        this.$refs.simpleRules.validate().then((success) => {
          if (success) {
            let data = {
              documentType: this.selected2.title,
              docNumber: this.number,
              dbelongsTo: this.selectedTo,
              makingDate: this.dateRelease,
              expireDate: this.dateEnd,
              docNote: this.notes,
            };
            // //console.log("/api/documents/" + this.$route.params.id);
            this.$http.post("/api/documents/" + this.$route.params.id, data).then(
              (response) => {
                // //console.log(response);
              },
              (this.selected2 = ""),
              (this.number = ""),
              (this.selectedTo = ""),
              (this.dateRelease = ""),
              (this.dateEnd = ""),
              (this.notes = "")
            );
            requestAnimationFrame(() => {
              this.$refs.simpleRules.reset();
            });
          }
        });
      });
    },
    Belongsto() {
      this.$http.get("/api/documents/" + this.$route.params.id).then((response) => {
        // //console.log(response.data.data);
        let persons = response.data.data;
        persons.forEach((el) => {
          this.optionTo.push(el.firstName);
        });
      });
    },
    next() {
      this.$router.push({
        name: "services",
        params: { id: this.$route.params.id },
      });
    },
  },
  created() {
    this.Belongsto();
  },
};
</script> 

<style scoped>
label {
  color: rgba(112, 192, 203, 255);
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.btn-bl {
  color: white;
  background-color: rgba(112, 192, 203, 255);
}

span {
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.align-left {
  left: 50px;
}
.but {
  top: 40px;
}
</style>
`
export const codeDefault = ''
